.header {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.header-logo {
  font-family: 'Bungee Shade', cursive;
  font-size: 50px;
  /*  color: #86bbd8; */
  margin: 30px 35px;
  margin-bottom: 0;
  display: inline;
  flex-grow: 1;
  text-align: left;
  /*  text-shadow: 0 0 10px #eb8282; */
  /* text-shadow: 0 0 10px #ffffff; */
}

.header-countdown {
  display: inline;
  text-align: right;
  flex-grow: 1;
  margin: 15px 20px;
  font-family: 'Open Sans', sans-serif;
  min-width: 310px;
}

@media (max-width: 800px) {
  .header-logo {
    text-align: center;
  }

  .header-countdown {
    text-align: center;
    display: block;
  }

  .header {
    display: block;
  }
}
