.home-screen {
  padding: 0 10px 60px 10px;
  min-height: 100%;
  flex: 1;
}

.hidden {
  display: none;
}

.make-your-list {
  /*  background-color: #50e3c240; */
}

.title {
  /* font-family: 'Pacifico', cursive; */
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin: 0;
}

.description {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  text-align: center;
  margin: 10px 0;
  /* text-shadow: 0 0 5px #eb8282; */
  font-weight: bold;
}

.list-x-button {
  float: right;
  margin: 0;
  cursor: pointer;
}

/* shared between list-element and list-search */
.list-row {
  margin: 10px 0;
  padding: 12px;
  max-width: 300px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  border-radius: 10px;
  color: white;
}

.list-element {
  background-color: #86bbd8;
  font-weight: bold;
  display: inline-block;
  width: 100%;
}

.list-element-disabled {
  background-color: #86bbd899;
  cursor: auto;
}

.list-search {
  width: 100%;
  background-color: #86bbd899;
  border: none;
}

.list-search::placeholder {
  color: white;
}

.list-search:focus {
  outline: none;
}

.search-result-container {
  position: absolute;
  background-color: white;
  max-width: 300px;
  margin-top: -10px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: solid 1px black;
  border-top: none;
  z-index: 1000;
}

.search-result-row {
  padding: 15px;
  margin: 0;
  color: black;
}

.search-result-row:hover {
  background-color: grey;
  cursor: pointer;
}

.search-result-row:hover:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: none;
}

.example-enter {
  opacity: 0.01;
  transform: translateY(-50px);
}

.example-enter.example-enter-active {
  opacity: 1;
  transform: none;
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}

.example-leave {
  opacity: 1;
  transform: none;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transform: translateY(-50px);
  transition: opacity 300ms ease-in, transform 500ms ease-in;
}
