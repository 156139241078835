.footer {
  width: 100%;
  left: 0;
  bottom: 0;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin-bottom: 30px;
}

a {
  color: white;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
