.pop-button-wrapper {
  text-align: center;
  padding: 20px 45px;
  background-color: #81b4d0;
  box-shadow: -3px 3px 0px #ffffff;
  border-radius: 10px;
  cursor: pointer;
  width: 175px;
  color: white;
  display: inline-block;
  font-size: 24px;
}

.pop-button-wrapper-small {
  padding: 15px 25px;
  width: 100px;
  font-size: 16px;
}

.pop-button-wrapper-clear {
  background-color: #000034;
  border: 1px solid white;
}

.pop-button-wrapper:not(:active):not(.pop-button-wrapper-loading):hover.pop-button-wrapper-enabled:hover {
  box-shadow: -1px 1px 0 #ffffff;
  transform: translateX(-2px) translateY(2px);
}

.pop-button-wrapper:active,
.pop-button-wrapper-disabled,
.pop-button-wrapper-loading {
  box-shadow: none;
  transform: translateX(-3px) translateY(3px);
}

.pop-button-wrapper-disabled {
  opacity: 0.5;
  cursor: auto;
}

.pop-button-wrapper-loading {
  color: transparent;
  cursor: auto;
}

.pop-button-text {
  display: inline-block;
  font-family: 'Bungee', cursive;
  text-align: center;
  display: inline;
}

.pop-button-text-disabled {
  box-shadow: none;
  cursor: auto;
}

.pop-button-text-loading {
  color: rgba(0, 0, 0, 0);
}

