/*
.unauthenticated-input {
  margin: 0px auto;
  display: block;
  background-color: #86bbd8;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  height: 30px;
  width: 50%;
  max-width: 300px;
  text-align: center;
  color: white;
  border-radius: 10px;
}

.unauthenticated-input::placeholder {
  color: white;
}

.unauthenticated-input:focus {
  outline: none;
}*/

.unauthenticated-input {
  font-family: 'Open Sans', sans-serif;
  margin: 10px auto;
  display: block;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid white;
  color: white;
  width: 250px;
  font-size: 18px;
  height: 30px;
  text-align: center;
}

.unauthenticated-input:-webkit-autofill {
  background-color: transparent !important;
}

.unauthenticated-input:placeholder-shown {
  opacity: 0.5;
}

.unauthenticated-input::placeholder,
.unauthenticated-input:not(:placeholder-shown) {
  color: white;
}

.unauthenticated-input:focus {
  outline: none;
  opacity: 1;
}

.unauthenticated-input-label {
  margin: 0px auto;
  width: 1000px;
  display: block;
}

.unauthenticated-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

.unauthenticated-container {
  margin: 0;
  padding: 50px 25px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.error-container {
  padding: 20px;
  opacity: 0.75;
}

.unauthenticated-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 30px;
  margin: 0 20px;
}

.unauthenticated-title-small {
  max-width: 500px;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
  margin: 0 auto;
}

.unauthenticated-subtitle {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  padding-top: 5px;
  line-height: 25px;
}

/* Splash screen */

.splash-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 0 30px;
  flex-wrap: wrap;
  max-width: 1200px;
  flex-grow: 1;
}
/*
@media (max-width: 800px) {
  .splash-container {
    flex-direction: column;
  }
}
*/

.splash-description {
  flex-grow: 4;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 450px;
}

@media (max-width: 500px) {
  .splash-description {
    min-width: 100px;
  }
}

.splash-buttons {
  flex-grow: 3;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tagline {
  font-family: 'Bungee', cursive;
  font-size: 64px;
  text-shadow: -3px 3px 0px #81b4d0;
  /*  text-shadow: -3px 3px 0px #eb8282; */
  margin: 0;
  max-width: 700px;
}

.subtagline {
  font-family: 'Open Sans', sans-serif;
  font-size: 26px;
  max-width: 650px;
}

.subtagline-emphasis {
  /* text-shadow: 0 0 4px #ffffff; */
  font-weight: bold;
}

