html {
  background-color: #000034;
  font-family: 'Open Sans', sans-serif;
}

.App {
  position: relative;
  text-align: center;
  height: 100%;
  background-color: #000034;
  color: white;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.user-row {
  background-color: #dae0e2;
  padding: 1px;
  margin: 10px;
  border-radius: 20px;
}

.Countdown {
  margin: 10px auto;
  padding-bottom: 20px;
}

.Countdown-col {
  display: inline-block;
}

.Countdown-col-element {
  display: inline-block;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong {
  font-size: 20px;
  text-align: center;
  /* text-shadow: 0 0 5px #eb8282; */
  /* text-shadow: 0 0 10px #ffffff; */
}
